<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="520px"
    persistent
    overlay-color="defaultBg"
  >
    <v-card class="mainBg">
      <v-form class="pb-10" ref="form" v-model="validForm">
        <div class="defaultColor--text headline pl-15 py-15">
          Edit Amount
        </div>
        
        <div class="px-15">
          <div class="pb-7">
            <span class="secondaryColor--text">Amount, $</span>
          </div>
          
          <v-text-field
            v-model.trim="formData.amount"
            background-color="defaultBg"
            flat
            solo
            type="number"
            :rules="rules"
          />
        </div>
        
        <div class="px-15">
          <div class="pb-7">
            <span class="secondaryColor--text">Amount Paid, $</span>
          </div>
          
          <v-text-field
            v-model.trim="paidAmount"
            background-color="defaultBg"
            flat
            solo
            readonly
          />
        </div>
        
        <div class="d-flex justify-end px-15 py-10">
          <v-btn
            class="subtitle-1 accentColor--text error mr-10"
            large
            depressed
            height="50"
            @click="closeDialog"
          >Cancel</v-btn>
          
          <v-btn
            class="subtitle-1 accentColor--text secondary"
            large
            depressed
            height="50"
            type="submit"
            @click.prevent="submitForm"
            :loading="loading"
          >Edit</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DialogEdtAmount',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    invoice: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    validForm: true,
    formData: {
      amount: null
    },
    paidAmount: null,
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState({
      loading: state => state.invoices.loadingAfterAction,
      actionSuccess: state => state.invoices.actionSuccess,
    }),
  },
  methods: {
    ...mapActions({
      editAmount: 'invoices/EDIT_AMOUNT',
    }),
    parseProps() {
      this.formData.amount = this.invoice.amount
      this.paidAmount = +this.invoice['amount_paid']
    },
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.editAmount({
          formData: this.formData,
          id: this.invoice.id
        })
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    }
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.parseProps()
        } else {
          this.amount = null
          this.rules = []
        }
      }
    },
    actionSuccess: {
      handler(value) {
        if (value) {
          this.closeDialog()
        }
      }
    }
  }
}
</script>
